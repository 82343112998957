<template>
  <v-card class="mx-auto mt-10 text-center" width="800" flat>
    <v-card-title class="d-flex justify-center text-h1 text-uppercase">
      Oups !
    </v-card-title>
    <v-card-text class="sky-grey--text text--darken-1 text-404 mt-2">
      Il semblerait que vous vous soyez éloigné de la zone de vol. Suivez le
      drone, il vous ramènera en lieu sûr !
    </v-card-text>
    <v-img
      height="320"
      src="/assets/photos/oops.jpg"
      lazy-src="/assets/photos/oops.jpg"
      alt="photo d'un drone à l'horizon"
    />
    <v-card-action class="d-flex justify-center">
      <v-btn
        color="sky-grey darken-1"
        class="white--text mt-6"
        rounded
        elevation="2"
        large
        @click="redirectPage"
      >
        Je retourne vers l'accueil
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-action>
  </v-card>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    redirectPage() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-404 {
  font-size: 28px;
  font-family: "fjalla one", sans-serif;
  letter-spacing: 1px;
  line-height: 2rem;
}
</style>